import styled from 'styled-components';
// styles
import { palette, mediaQuery } from 'cds/styles';

/**
 * 오픈준비 배지
 */
const ReadyCover = () => {
  return (
    <Wrapper>
      <img
        src={`${process.env.NEXT_PUBLIC_ASSET_PREFIX}/img/class/icon_time_to_deal.png`}
        alt="타임 특가"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  top: 0;
  left: 0;
  padding: 4px;
  font-size: 18px;
  letter-spacing: 0.2rem;
  color: ${palette.white};
  background-color: ${palette.dim.basic};

  & > img {
    width: 72px;
    height: 72px;
  }

  ${mediaQuery.medium} {
    font-size: 20px;
  }
`;

export default ReadyCover;
