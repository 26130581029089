import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
// components
import StarIcon from 'components/atoms/icons/Star';
import ClassCover from 'components/atoms/photos/ClassCover';
import Avatar from 'components/atoms/photos/Avatar';
import MessageBadge from 'components/molecules/event/badge/Message';
import CloseCover from 'components/molecules/event/badge/Close';
import ReadyCover from 'components/molecules/event/badge/Ready';
// hooks
import useCommonBridge from 'hooks/bridge/useCommonBridge';
import useWebview from 'hooks/common/useWebview';
import { useSelector } from 'hooks/common/useStore';
// utils
import { formatToPrice } from 'lib/utils/format/number';
import { iosTimeFormat, dateTimeFormatter, getKoreaDate } from 'lib/utils/format/time';
// styles
import * as typography from 'cds/typography';
import { palette, mediaQuery } from 'cds/styles';

/**
 * 클래스 아이템 event 타입 카드
 * @param {object} props
 * @param {import('types/slices/event').EventClass} props.data 클래스 정보
 */
const EventItem = ({ data }) => {
  const router = useRouter();
  const { asPath } = router;

  const userInfo = useSelector(state => state.user.info);
  const eventInfo = useSelector(state => state.event.plan.info);
  const IS_ROWITEM = eventInfo.id !== 51;
  const IS_EARLYBIRD_EVENT = eventInfo.id === 1;
  const IS_MONTHLY_EVENT = asPath.indexOf('month') > -1;

  const { goToClassDetail, goToProfile } = useCommonBridge();
  const { isWebview } = useWebview();

  // 타임딜의 경우, 해당 클래스의 close_date 값이 없어, 이벤트가 끝나는 시간 기준으로 한다.
  const CLOSE_TIEM_DEAL = new Date(iosTimeFormat(eventInfo.close_date)) < getKoreaDate();

  const getDateDiff = (start, end) => {
    return ((start.getTime() - end.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0) * 1;
  };

  const setMidnight = date => {
    return new Date(new Date(date).setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 24 * 1);
  };

  const onClickClass = e => {
    if (!IS_MONTHLY_EVENT && setMidnight(data.close_date).getTime() < getKoreaDate().getTime()) {
      e.preventDefault();
      return;
    }

    if (CLOSE_TIEM_DEAL) {
      e.preventDefault();
      return;
    }

    if (!userInfo.id) {
      window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/classDetail/${data.class_id}`;
      return;
    }

    goToClassDetail(data.class_id);
  };

  const onMouseDownCard = e => {
    if (!IS_MONTHLY_EVENT && setMidnight(data.close_date).getTime() < getKoreaDate().getTime()) {
      e.preventDefault();
      return;
    }

    // 타임딜의 경우, 해당 클래스의 close_date 값이 없어, 이벤트가 끝나는 시간 기준으로 한다.
    if (CLOSE_TIEM_DEAL) {
      e.preventDefault();
      return;
    }

    let targetURL = '';

    if (isWebview) {
      return;
    }

    if (e.buttons === 4) {
      e.stopPropagation();
      targetURL = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/classDetail/${data.class_id}`;
      window.open(targetURL, '_blank');
    }
  };

  const profileDelegator = e => {
    if (setMidnight(data.close_date).getTime() < getKoreaDate().getTime()) {
      e.preventDefault();
      return;
    }

    // 타임딜의 경우, 해당 클래스의 close_date 값이 없어, 이벤트가 끝나는 시간 기준으로 한다.
    if (CLOSE_TIEM_DEAL) {
      e.preventDefault();
      return;
    }

    e.stopPropagation();
    goToProfile(data.coach_id);
  };

  const onMouseDownProfile = e => {
    if (isWebview) {
      return;
    }

    if (e.buttons === 4) {
      e.stopPropagation();
      window.open(`${process.env.NEXT_PUBLIC_DEFAULT_HOST}/new/user/${data.coach_id}`, '_blank');
    }
  };

  const StatusCover = () => {
    // 얼리버드 이벤트에만 마감된 클래스가 존재한다.
    if (IS_EARLYBIRD_EVENT) {
      if (setMidnight(data.close_date).getTime() < getKoreaDate().getTime()) {
        return <CloseCover />;
      }

      return <></>;
    }

    // 항시 오픈 이벤트 (예: 초저가 이벤트)
    if (!eventInfo.open_date || !eventInfo.close_date) {
      return <></>;
    }

    // 종료된 이벤트 (예: 타입딜)
    if (CLOSE_TIEM_DEAL) {
      return <CloseCover />;
    }

    // 현재 진행 중인 이벤트
    if (
      new Date(iosTimeFormat(eventInfo.open_date)) < getKoreaDate() &&
      new Date(iosTimeFormat(eventInfo.close_date)) > getKoreaDate()
    ) {
      return <></>;
    }

    // 오픈 30분 전 체크
    const earlier =
      Math.floor(
        (new Date(iosTimeFormat(eventInfo.open_date)).getTime() - getKoreaDate().getTime()) /
          1000 /
          60,
      ) > 0 &&
      Math.floor(
        (new Date(iosTimeFormat(eventInfo.open_date)).getTime() - getKoreaDate().getTime()) /
          1000 /
          60,
      ) <= 30;

    if (earlier) {
      return <ReadyCover />;
    }

    return <CloseCover />;
  };

  return (
    <Item IS_ROWITEM={IS_ROWITEM} onClick={onClickClass} onMouseDown={onMouseDownCard}>
      <CoverWrapper IS_ROWITEM={IS_ROWITEM}>
        <ClassCover src={data.class_photo} />
        {IS_EARLYBIRD_EVENT && (
          <>
            {/* close_date가 당일 */}
            {getDateDiff(dateTimeFormatter(setMidnight(data.close_date)), getKoreaDate()) === 0 && (
              <MessageBadge>오늘 마감</MessageBadge>
            )}

            {/* close_date 7일 이상이고 */}
            {getDateDiff(dateTimeFormatter(setMidnight(data.close_date)), getKoreaDate()) >= 7 && (
              <MessageBadge>신규 등록</MessageBadge>
            )}

            {/* close_date가 0일 초과 7일 미만일 떄, D-DAY */}
            {getDateDiff(dateTimeFormatter(setMidnight(data.close_date)), getKoreaDate()) > 0 &&
              getDateDiff(dateTimeFormatter(setMidnight(data.close_date)), getKoreaDate()) < 7 && (
                <MessageBadge>
                  {(
                    (dateTimeFormatter(setMidnight(data.close_date)).getTime() -
                      getKoreaDate().getTime()) /
                    (1000 * 60 * 60 * 24)
                  ).toFixed(0)}
                  일 남음
                </MessageBadge>
              )}
          </>
        )}

        {!IS_MONTHLY_EVENT && <StatusCover />}
      </CoverWrapper>

      <Info
        IS_ROWITEM={IS_ROWITEM}
        isClose={
          !IS_MONTHLY_EVENT &&
          // 타임딜의 경우, 해당 클래스의 close_date 값이 없어, 이벤트가 끝나는 시간 기준으로 한다.
          (CLOSE_TIEM_DEAL ||
            getDateDiff(dateTimeFormatter(setMidnight(data.close_date)), getKoreaDate()) < 0)
        }
      >
        <div>
          <AvatarButton onClick={profileDelegator} onMouseDown={onMouseDownProfile}>
            <Avatar width={32} height={32} src={data.coach_photo} />
          </AvatarButton>
        </div>
        <div>
          <Title>{data.class_name}</Title>

          <Summary>
            <Coach onClick={profileDelegator} onMouseDown={onMouseDownProfile}>
              {data.coach_name}
            </Coach>
            {data.curriculum_cnt && (
              <CurriculumCount>
                <span>{`${data.curriculum_cnt}강`}</span>
              </CurriculumCount>
            )}
          </Summary>

          <Price>
            {/* 평생 소장 여부 판단 */}
            {eventInfo.life_flag === 'Y' && <SalePer>평생소장</SalePer>}
            {eventInfo.life_flag === 'N' && (
              <>
                {!!parseInt(data.payment_info_data1 || data.price_sale, 10) && (
                  <SalePer>{Math.floor(data.payment_info_data1 || data.price_sale)}%</SalePer>
                )}
              </>
            )}
            {(data.payment || data.payment_info_data4 || data.payment_price) && (
              <SalePrice>
                <span>월</span>
                {formatToPrice(data.payment_info_data4 || data.payment || data.payment_price)}원
              </SalePrice>
            )}
            <i>{eventInfo.life_flag === 'Y' && 'X 3개월'}</i>
          </Price>

          {!!(data.review_score || data.star_avg || data.group_signup_member_count) && (
            <Status>
              {(!!data.review_score || !!data.star_avg) && (
                <span>
                  <StarIcon type="S" isActive />
                  {data.review_score
                    ? data.review_score.toFixed(1)
                    : data.star_avg && data.star_avg.toFixed(1)}
                </span>
              )}
              {/* 얼리버드할인일 경우, 남음 표시, 나머지는 멤버 및 활동 표시 */}
              {IS_EARLYBIRD_EVENT && (
                <span>
                  {data.group_member_limit -
                    (data.group_signup_member_count % data.group_member_limit)}
                  명 남음
                </span>
              )}
              {!IS_EARLYBIRD_EVENT && (
                <span>
                  {!!data.signup_cnt && <>멤버 {formatToPrice(data.signup_cnt)}</>}
                  {!!data.mission_cnt && <>&nbsp;&nbsp;활동 {formatToPrice(data.mission_cnt)}</>}
                </span>
              )}
            </Status>
          )}

          {/* 최초 오픈 할인 이벤트 여부 */}
          {eventInfo.life_flag === 'N' && data.star_cnt === 0 && (
            <FirstOpenBadge>최초 오픈 할인 이벤트</FirstOpenBadge>
          )}
        </div>
      </Info>
    </Item>
  );
};

const skeleton = css`
  background-color: ${palette.grey010};
  border-radius: 4px;
`;

const Item = styled.div`
  position: relative;
  cursor: pointer;
  ${({ IS_ROWITEM }) =>
    IS_ROWITEM
      ? css`
          display: flex;
          align-items: flex-start;
          border-bottom: 1px solid ${palette.border.b1};
          padding: 12px 0;
        `
      : css`
          display: block;
        `};

  &:active {
    background-color: ${palette.grey010};
  }

  ${mediaQuery.medium} {
    display: block;
    border-bottom: 0;
  }
`;

const CoverWrapper = styled.div`
  position: relative;
  flex: 1 1 0;

  ${({ IS_ROWITEM }) =>
    IS_ROWITEM
      ? css`
          min-width: 40%;
          width: 40%;
          max-width: 40%;
        `
      : css`
          width: 100%;
          max-width: 100%;
        `};

  ${mediaQuery.medium} {
    width: 100%;
    max-width: 100%;
  }
`;

const Info = styled.div`
  flex: 1 1 0%;
  display: flex;
  align-items: flex-start;
  opacity: ${({ isClose }) => (isClose ? 0.5 : 1)};

  & > div:first-of-type {
    display: none;
    flex: 0 auto;
    width: 32px;
    padding: 4px 0 0;
    margin: 0 12px 0 0;
  }

  & > div:last-of-type {
    overflow: hidden;
    flex: 0 auto;
    margin: 0 0 0 12px;
  }

  ${({ IS_ROWITEM }) =>
    IS_ROWITEM
      ? css`
          & > div:first-of-type {
            display: none;
          }

          & > div:last-of-type {
            margin: 0 0 0 12px;
          }
        `
      : css`
          margin-top: 8px;

          & > div:first-of-type {
            display: block;
          }

          & > div:last-of-type {
            margin: 0;
          }
        `};

  ${mediaQuery.medium} {
    margin-top: 8px;

    & > div:first-of-type {
      display: block;
    }

    & > div:last-of-type {
      margin: 0;
    }
  }
`;

const AvatarButton = styled.button`
  flex: 0 auto;
  width: 32px;
  height: 32px;
`;

const Title = styled.p`
  display: block;
  display: -webkit-box;
  position: relative;
  padding: 0;
  margin-bottom: 4px;
  ${typography.subtitle4_medium}
  color: ${palette.grey080};
  white-space: break-spaces;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  &::after {
    content: '...';
    text-align: right;
    bottom: 0;
    right: 0;
    width: 25%;
    display: block;
    position: absolute;
    height: calc(1em * 1.2);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%);
  }

  @supports (-webkit-line-clamp: 2) {
    &::after {
      display: none;
    }
  }

  &:empty {
    ${skeleton};
    height: 20px;
  }

  ${mediaQuery.large} {
    font-size: 15px;
  }
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  ${typography.caption3_regular}
  color: ${palette.grey040};

  ${mediaQuery.large} {
    ${typography.caption2_regular}
  }
`;

const Coach = styled.span`
  overflow: hidden;
  flex: 0 auto;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:empty {
    ${skeleton};
    width: 150px;
    height: 22px;
  }
`;

const CurriculumCount = styled.span`
  flex: 0 0 auto;

  &::before {
    content: '·';
    margin: 0 4px;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & > i {
    margin-left: 8px;
    font-size: 14px;
    color: ${palette.grey040};
  }
`;

const SalePer = styled.span`
  flex: 0 auto;
  margin-right: 6px;
  ${typography.subtitle3_bold}
  color: ${palette.red060};
  font-weight: 700;

  ${mediaQuery.large} {
    font-size: 16px;
    letter-spacing: 0;
  }

  &:empty {
    ${skeleton};
    width: 28px;
    height: 22px;
  }
`;

const SalePrice = styled.span`
  display: flex;
  align-items: baseline;
  flex: 0 auto;
  ${typography.subtitle3_bold}
  color: ${palette.grey080};

  & span {
    margin-right: 4px;
    font-size: 14px;
  }

  &:empty {
    ${skeleton};
    width: 76px;
    height: 22px;
  }

  ${mediaQuery.large} {
    font-size: 16px;
    letter-spacing: 0;
  }
`;

const Status = styled.p`
  display: flex;
  align-items: center;
  ${typography.body3_regular}
  font-size: 11px;
  color: ${palette.grey080};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${mediaQuery.large} {
    font-size: 13px;
  }

  & span {
    display: flex;
    align-items: center;
    flex: 0 auto;

    svg {
      margin-right: 2px;
    }
  }

  & span ~ span::before {
    display: inline-block;
    content: '';
    width: 1px;
    margin: 0 4px;
    height: 8px;
    background: ${palette.grey040};
  }

  &:empty {
    ${skeleton};
    width: 110px;
    height: 18px;
  }
`;

const FirstOpenBadge = styled.div`
  display: inline-block;
  padding: 0 4px 1px;
  margin-top: 8px;
  border: 1px solid ${palette.red060};
  color: ${palette.red060};
  border-radius: 2px;
  font-size: 12px;
`;

export default EventItem;
