import styled from 'styled-components';
// styles
import { palette, mediaQuery } from 'cds/styles';

/**
 * 메세지 배지
 * @param {object} props
 * @param {string} props.children
 */
const MessageBadge = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  position: absolute;
  width: auto;
  height: 24px;
  border-radius: 3px;
  top: 4px;
  left: 4px;
  padding: 4px;
  font-size: 10px;
  color: ${palette.white};
  background-color: ${palette.red060};

  ${mediaQuery.medium} {
    top: 8px;
    left: 8px;
  }
`;

export default MessageBadge;
