import styled from 'styled-components';
// styles
import { palette, mediaQuery } from 'cds/styles';

/**
 * 닫기 배지
 */
const CloseCover = () => {
  return <Wrapper>CLOSE</Wrapper>;
};

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  top: 0;
  left: 0;
  padding: 4px;
  font-size: 18px;
  letter-spacing: 0.2rem;
  color: ${palette.white};
  background-color: ${palette.dim.basic};

  ${mediaQuery.medium} {
    font-size: 24px;
  }
`;

export default CloseCover;
