import styled, { css } from 'styled-components';
// components
import ColumnItem from 'components/molecules/class/card/ColumnItem';
import EventItem from 'components/molecules/class/card/EventItem';
// styles
import { mediaQuery } from 'cds/styles';

/**
 * 클래스 Card 그리드 형태
 * @param {object} props
 * @param {'MAIN' | 'EVENT'} props.type 카드 타입
 * @param {import('types/slices/main').RecommendClass[]| import('types/slices/main').LowPriceClass[]} 클래스 목록
 * @param {boolean} props.isLoading 로딩 여부
 * @param {boolean} props.showSalePer 할인율 노출 여부
 * @param {boolean} props.showPrice 가격 노출 여부
 * @param {object[default|small|medium|large|xlarge]} props.slidePerView 뷰포트에 보여질 슬라이드 비율
 * @param {boolean} props.showBookmark 북마크 추가/제거 기능 여부
 */
const ClassGridList = ({
  type = 'MAIN',
  list = [],
  isLoading,
  showSalePer,
  showPrice,
  slidePerView,
  showBookmark = false,
}) => {
  const skeletonArray = new Array(10).fill(0);
  const data = isLoading ? skeletonArray : list;

  const Items = data.map((v, i) => {
    const variant = v.image_small ? 'banner' : 'class';
    return (
      <Item key={`${v.class_id}${i}`} slidePerView={slidePerView} variant={variant}>
        {type === 'MAIN' && (
          <ColumnItem
            data={v}
            showSalePer={showSalePer}
            showPrice={showPrice}
            showProfile
            variant={variant}
            showBookmark={showBookmark}
          />
        )}
        {type === 'EVENT' && <EventItem data={v} />}
      </Item>
    );
  });

  return <List>{Items}</List>;
};

const List = styled.ul`
  padding: 0 16px;
  margin-left: -16px;

  ${mediaQuery.large} {
    padding: 0 0;
  }
`;

const createCss = obj => {
  let styles = '';

  for (let i = 0, len = obj.length; i < len; i++) {
    if (obj[i].break === 'default') {
      styles += `
					width: calc(100% / ${obj[i].view});
        
          &:nth-of-type(-n + ${obj[i].view}) {
            margin-top: 0;
          }
			`;
      continue;
    }

    styles += `
			@media (min-width: ${obj[i].break}px) {
				width: calc(100% / ${obj[i].view});
				&:nth-of-type(-n + ${obj[i].view}) {
          margin-top: 0;
        }
			}
		`;
  }

  return css`
    ${styles}
  `;
};

const Item = styled.li`
  display: ${({ variant }) => (variant === 'banner' ? 'none' : 'inline-block')};
  width: 100%;
  padding-left: 16px;
  margin-top: 20px;
  font-size: 0;
  transition: width 0.2s ease;
  vertical-align: top;

  ${({ slidePerView }) => createCss(slidePerView)};

  ${mediaQuery.large} {
    display: inline-block;
  }
`;

export default ClassGridList;
